import Bg404Page from 'i/bg_404.jpg';

const APP_DATA = {
	header: {
		menuTriggerButtonTitle: {
			de: 'menü',
			fr: 'menu',
			it: 'menu',
			en: 'menu',
		},
		menuTriggerButtonCloseTitle: {
			de: 'schliessen',
			fr: 'fermer',
			it: 'chiudere',
			en: 'close',
		},
		menuContent: {
			cookiePreferences: {
				de: 'Cookie Einstellungen',
				fr: 'Paramètres des Cookies',
				it: 'Impostazioni Cookie',
				en: 'Cookie Settings',
			},
		},
	},
	notFoundPage: {
		bgImage: Bg404Page,
		bgImageAlt: 'Error 404',
	},
	faqPage: {
		typeYourQuestion: {
			en: 'Type your question',
			fr: 'Tape ta question',
			it: 'Scrivi la tua domanda',
			de: 'Tippe deine Frage ein',
		},
		back: {
			en: 'Back',
			fr: 'Retour',
			it: 'Indietro',
			de: 'Zurück',
		},
		foundMultipleAnswersFmt: {
			en: 'Found {numAnswers} answers',
			fr: '{numAnswers} réponses trouvées',
			it: 'Trovate {numAnswers} risposte',
			de: 'Es wurden {numAnswers} Antworten gefunden',
		},
		foundSingleAnswer: {
			en: 'Found one answer',
			fr: 'Une réponse trouvée',
			it: 'Trovata una risposta',
			de: 'Eine Antwort gefunden',
		},
	},
	invitationPage: {
		registrationSection: {
			buttonFormSubmit: {
				de: 'Absenden',
				en: 'Send',
				fr: 'Envoyer',
				it: 'Send',
			},
			formFields: [
				{
					id: 'invitation_field_0',
					labelTitle: {
						de: 'Company *',
						en: 'Company *',
						fr: 'Company *',
						it: 'Company *',
					},
					type: 'text',
					name: 'company',
					placeholder: {
						de: '',
						en: '',
						fr: '',
						it: '',
					},
					isRequired: true,
				},
				{
					id: 'invitation_field_1',
					labelTitle: {
						de: 'Email *',
						en: 'Email *',
						fr: 'Email *',
						it: 'Email *',
					},
					type: 'email',
					name: 'email',
					placeholder: {
						de: '',
						en: '',
						fr: '',
						it: '',
					},
					isRequired: true,
				},
				{
					id: 'invitation_field_2',
					labelTitle: {
						de: 'Game *',
						en: 'Game *',
						fr: 'Game *',
						it: 'Game *',
					},
					type: 'select',
					name: 'game',
					placeholder: {
						de: '',
						en: '',
						fr: '',
						it: '',
					},
					options: [
						{
							value: 'game 1',
							label: '20.02.2021 / HC Fribourg - Gotteron',
						},
						{
							value: 'game 2',
							label: '20.02.2021 / HC Fribourg - Gotteron',
						},
						{
							value: 'game 3',
							label: '20.02.2021 / HC Fribourg - Gotteron',
						},
						{
							value: 'game 4',
							label: '20.02.2021 / HC Fribourg - Gotteron',
						},
						{
							value: 'game 5',
							label: '20.02.2021 / HC Fribourg - Gotteron',
						},
						{
							value: 'game 6',
							label: '20.02.2021 / HC Fribourg - Gotteron',
						},
						{
							value: 'game 7',
							label: '20.02.2021 / HC Fribourg - Gotteron',
						},
						{
							value: 'colocation',
							label: 'Colocation',
						},
						{
							value: 'servers_equipment',
							label: 'SERVERS & EQUIPMENT',
						},
						{
							value: 'сlouds',
							label: 'Clouds',
						},
					],
					isRequired: true,
				},
				{
					id: 'invitation_field_3',
					labelTitle: {
						de: 'Alternative Game',
						en: 'Alternative Game',
						fr: 'Alternative Game',
						it: 'Alternative Game',
					},
					type: 'select',
					name: 'alternativeGame',
					placeholder: {
						de: '',
						en: '',
						fr: '',
						it: '',
					},
					options: [
						{
							value: 'game 1',
							label: '20.02.2021 / HC Fribourg - Gotteron',
						},
						{
							value: 'game 2',
							label: '20.02.2021 / HC Fribourg - Gotteron',
						},
						{
							value: 'game 3',
							label: '20.02.2021 / HC Fribourg - Gotteron',
						},
					],
				},
				{
					id: 'invitation_field_4',
					labelTitle: {
						de: 'Alternative Game',
						en: 'Alternative Game',
						fr: 'Alternative Game',
						it: 'Alternative Game',
					},
					type: 'select',
					name: 'alternativeGameSecond',
					placeholder: {
						de: '',
						en: '',
						fr: '',
						it: '',
					},
					options: [
						{
							value: 'game 1',
							label: '20.02.2021 / HC Fribourg - Gotteron',
						},
						{
							value: 'game 2',
							label: '20.02.2021 / HC Fribourg - Gotteron',
						},
						{
							value: 'game 3',
							label: '20.02.2021 / HC Fribourg - Gotteron',
						},
					],
				},
				{
					id: 'invitation_field_5',
					labelTitle: {
						de: 'Number of participants (max. 3)',
						en: 'Number of participants (max. 3)',
						fr: 'Number of participants (max. 3)',
						it: 'Number of participants (max. 3)',
					},
					type: 'number',
					name: 'numberOfParticipants',
					placeholder: {
						de: '',
						en: '',
						fr: '',
						it: '',
					},
				},
			],
		},
	},
	alertMessages: {
		fieldRequired: {
			en: 'This field is required',
			de: 'This field is required',
			fr: 'This field is required',
			it: 'This field is required',
		},
		fieldInvalidCharacters: {
			en: 'Invalid characters',
			de: 'Invalid characters',
			fr: 'Invalid characters',
			it: 'Invalid characters',
		},
		emailFieldRequired: {
			en: 'Email is required',
			de: 'Email is required',
			fr: 'Email is required',
			it: 'Email is required',
		},
		emailFieldValid: {
			en: 'Please enter a valid email',
			de: 'Please enter a valid email',
			fr: 'Please enter a valid email',
			it: 'Please enter a valid email',
		},
		registrationFormSuccessfullySend: {
			en: 'Form successfully sent!',
			de: 'Form successfully sent!',
			fr: 'Form successfully sent!',
			it: 'Form successfully sent!',
		},
		registrationFormError: {
			en: 'The form was not submitted, please try again later',
			de: 'Fehler',
			fr: 'Erreur',
			it: 'Error',
		},
		emptyFields: 'Please fill in all fields',
	},
};

export default APP_DATA;
