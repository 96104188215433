import React, { useContext } from 'react';
import { AppDataContext } from 'context/appData';

const SideLogoList = React.forwardRef(({
	props,
}, ref) => {
	const { themeSettings } = useContext(AppDataContext);

	return (
		themeSettings && themeSettings.general_settings && (
			<div className="side_logo_w js-global-element" ref={ref}>
				<div className="side_logo_in">
					<ul className="side_logo_list">
						{themeSettings.general_settings.side_logos && themeSettings.general_settings.side_logos.map(({
							logo,
						}, index) => {
							return (
								<li className="side_logo_item" key={index}>
									<div className="side_logo_item_in">
										{logo.url && (
											<img className="side_logo_img" src={logo.url} alt={logo.alt} />
										)}
									</div>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		)
	);
});

export default SideLogoList;
