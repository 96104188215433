/* eslint-disable indent, quotes */
export const TRANSLATION_EN = {
  i18n: {
    language: "Language",
    languageName: "English",
  },
  rackReturn: {
    pickupLocation: "Pickup Location",
    pickupStreet: "Street",
    pickupCity: "City",
    pickupCityPlaceholder: "Pickup City",
    doTrackLocation: "✓ My position is being tracked",
    dontTrackLocation: "✖ My position is no longer tracked",
    companyName: "Company",
    enterYourCompany: "Enter the name of your company",
    contactName: "Contact Name",
    enterYourName: "Enter Your Name",
    returnForRackFmt: "Return Form For Rack No. {}",
    rackId: "Rack No.",
    comments: "Comments",
    enterAnyComments: "Enter any Comments",
    submit: "Submit",
    companyNameEmptyError: "Company name is required",
    contactNameEmptyError: "Contact name is required",
    addressEmptyError: "Address oder map location required",
  },
  rackReturnConfirmation: {
    thankYou: "Thanks. We'll be there shortly ...",
  },
  rackReturnEmail: {
    subjectFmt: "Return of Rack No. {}",
    rackIdReadyAtCompanyInCityFmt: "Rack No. {rackId} is ready for pickup at {companyName} in {city}",
    rackIdReadyInCityFmt: "Rack No. {rackId} is ready for pickup in {city}",
    bodyHtmlFmt: "<p>{heading}</p><p><ul><li>Company: <strong>{companyName}</strong></li><li>Contact: <strong>{contactName}</strong></li><li>Pickup Address: <strong>{address}</strong></li><li>Language: <strong>{language}</strong></li></ul></p><p>Comments:<br />{comments}</p>",
  },
};
