import { UiContext } from 'context/ui';
import React, { useContext } from 'react';
import Slider from 'react-slick';
import { TABLET_MEDIA_POINT } from 'utils/constant';
import ServicesItem from './ServicesItem';

const ServiceSlider = ({
	servicesList,
}) => {
	const { windowWidth } = useContext(UiContext);

	return (
		servicesList && servicesList.length ? (
			<div className="section_slider_w">
				{windowWidth >= TABLET_MEDIA_POINT ? (
					<div className="section_slider servicesSlider">
						{servicesList.map(({
							service_image,
							service_title,
							service_url,
							service_description,
						}, index) => {
							return (
								<div className="section_slide_item" key={index}>
									<ServicesItem
										url={service_url}
										image={service_image}
										title={service_title}
										description={service_description}
									/>
								</div>
							);
						})}
					</div>
				) : (
					<Slider
						className="section_slider"
						{...{
							infinite: false,
							slidesToShow: 1,
							variableWidth: true,
							centerMode: true,
							centerPadding: 0,
							arrows: false,
							dots: true,
							responsive: [
								{
									breakpoint: 768,
									settings: {
										infinite: false,
										variableWidth: true,
									},
								},
							],
						}}
					>
						{servicesList.map(({
							service_image,
							service_title,
							service_url,
							service_description,
						}, index) => {
							return (
								<div className="section_slide_item" key={index}>
									<ServicesItem
										url={service_url}
										image={service_image}
										title={service_title}
										description={service_description}
									/>
								</div>
							);
						})}
					</Slider>
				)}
			</div>
		) : null
	);
};

export default ServiceSlider;
