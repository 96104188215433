import React,
{
	useRef,
	useContext,
	useState,
	useEffect,
} from 'react';
import { gsap } from 'gsap';
import { SplitText } from 'libs/SplitText';
import { MorphSVGPlugin } from 'libs/MorphSVGPlugin';

import ReactHtmlParser from 'react-html-parser';
import { Map } from 'components/Map';
import { AppDataContext } from 'context/appData';
import { getPageById } from 'utils';
import { GLOBAL_PAGE_ANIM_DELAY, TABLET_MEDIA_POINT } from 'utils/constant';
import { UiContext } from 'context/ui';
import { MAP_OVERLAY_PATH } from './constant';

gsap.registerPlugin(SplitText, MorphSVGPlugin);

const ContactsPage = () => {
	const {
		pagesData,
		langApp,
	} = useContext(AppDataContext);
	const { faqBlockAnimTl, windowWidth, isPreloaderActive } = useContext(UiContext);
	const [currentPageData, setCurrentPageData] = useState(null);
	const [isMapOpen, setMapOpen] = useState(false);

	const [pageAnimation] = useState(gsap.timeline({
		paused: true,
	}));

	const [tlTogleMap] = useState(gsap.timeline({
		paused: true,
	}));

	const $sectionTitle = useRef();
	const socialListItems = useRef([]);
	const $mapWrapper = useRef();
	const $mapInner = useRef();
	const $contactWrapper = useRef();
	const $openMapBtn = useRef();
	const $closeMapBtn = useRef();
	const mapOpen = useRef(false);

	const getPageData = () => {
		const currentData = getPageById(pagesData, 38);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.data) {
			setCurrentPageData(preparedCurrentPageData.data);
		}
	};

	const pageAnim = () => {
		const $sectionTitleLetters = new SplitText($sectionTitle.current, { type: 'chars' });

		const CLIP_PATH_CENTER_POSITION = windowWidth >= TABLET_MEDIA_POINT ? MAP_OVERLAY_PATH.centerPosition : MAP_OVERLAY_PATH.centerMobilePosition;

		pageAnimation
			.addLabel('start')
			.fromTo($sectionTitleLetters.chars, {
				yPercent: -200,
				opacity: 0,
			}, {
				yPercent: 0,
				opacity: 1,
				stagger: 0.1,
				duration: 0.6,
				ease: 'power2.out',
			}, 'start')
			.add(faqBlockAnimTl.tweenFromTo('onload_anim_start', 'onload_anim_end'), 'start')
			.addLabel('listStep')
			.fromTo(['.contact_item', '.contact_link_item'], {
				xPercent: gsap.utils.wrap([-25, 25]),
				opacity: 0,
			}, {
				xPercent: 0,
				opacity: 1,
				stagger: gsap.utils.wrap([0.1, -0.1]),
				ease: 'power2.out',
			}, 'start+=.3')
			.fromTo('.contact_col_decor', {
				opacity: 0,
			}, {
				opacity: 0.35,
				duration: 0.3,
				ease: 'power2.out',
			}, 'start+=.4')
			.fromTo(socialListItems.current, {
				opacity: 0,
			}, {
				opacity: 1,
				stagger: 0.1,
				ease: 'power2.out',
			}, 'start+=.4')
			.fromTo($mapWrapper.current, {
				'clip-path': MAP_OVERLAY_PATH.startPosition,
			}, {
				'clip-path': CLIP_PATH_CENTER_POSITION,
				duration: 0.2,
				ease: 'none',
			}, 'start+=.2')
			.fromTo($openMapBtn.current, {
				opacity: 0,
				y: '5em',
				x: '-2em',
				pointerEvents: 'none',
			}, {
				opacity: 1,
				y: 0,
				x: 0,
				pointerEvents: 'auto',
				ease: 'none',
			});
	};

	const initToggleMap = () => {
		const $sideLogoW = document.querySelector('.side_logo_w');

		const CLIP_PATH_CENTER_POSITION = windowWidth >= TABLET_MEDIA_POINT ? MAP_OVERLAY_PATH.centerPosition : MAP_OVERLAY_PATH.centerMobilePosition;

		tlTogleMap
			.addLabel('open_start')
			.to($openMapBtn.current, {
				opacity: 0,
				pointerEvents: 'none',
			}, 'open start')
			.fromTo($mapWrapper.current, {
				'clip-path': CLIP_PATH_CENTER_POSITION,
			}, {
				'clip-path': MAP_OVERLAY_PATH.finishPosition,
				duration: 0.2,
				ease: 'none',
				immediateRender: false,
			}, 'open start')
			.fromTo($mapInner.current, {
				YPercent: 0,
			}, {
				yPercent: -32,
				duration: 1,
				ease: 'power1.out',
			}, 'open start')
			.to([$openMapBtn.current, $sideLogoW], {
				opacity: 0,
				pointerEvents: 'none',
			}, 'open start')
			.fromTo($closeMapBtn.current, {
				y: '-10em',
				opacity: 0,
				pointerEvents: 'none',
			}, {
				y: 0,
				opacity: 1,
				pointerEvents: 'auto',
			})
			.to($mapWrapper.current, {
				pointerEvents: 'auto',
			})
			.addLabel('open_end')
			.addLabel('close_start')
			.to($closeMapBtn.current, {
				y: '-10em',
				opacity: 0,
				pointerEvents: 'none',
			}, 'close_start')
			.fromTo($mapWrapper.current, {
				'clip-path': MAP_OVERLAY_PATH.finishPosition,
			}, {
				'clip-path': CLIP_PATH_CENTER_POSITION,
				duration: 0.2,
				ease: 'none',
				immediateRender: false,
			}, 'close_start')
			.to($mapWrapper.current, {
				pointerEvents: 'none',
			}, 'close_start')
			.fromTo($mapInner.current, {
				YPercent: -32,
			}, {
				yPercent: 0,
				duration: 1,
				ease: 'power1.out',
			})
			.to([$openMapBtn.current, $sideLogoW], {
				opacity: 1,
				pointerEvents: 'auto',
			})
			.addLabel('close_end');
	};

	const toggleMap = () => {
		if (!tlTogleMap.isActive()) {
			if (!mapOpen.current) {
				mapOpen.current = true;
				tlTogleMap.tweenFromTo('open_start', 'open_end');
				setMapOpen(true);
			} else {
				mapOpen.current = false;
				setMapOpen(false);
				tlTogleMap.tweenFromTo('close_start', 'close_end');
			}
		}
	};

	useEffect(() => {
		if (pagesData) {
			getPageData();
		}
	}, [pagesData]);

	useEffect(() => {
		if (currentPageData) {
			pageAnim();
			initToggleMap();
		}
	}, [currentPageData]);

	useEffect(() => {
		if (!isPreloaderActive) {
			gsap.delayedCall(GLOBAL_PAGE_ANIM_DELAY, () => {
				pageAnimation.play();
			});
		}
	}, [isPreloaderActive]);

	const mouseWheelHandler = (e) => {
		const upDirection = e.deltaY < 0;

		if (!upDirection && !mapOpen.current) {
			toggleMap();
		}
	};

	useEffect(() => {
		if (windowWidth >= TABLET_MEDIA_POINT) {
			window.addEventListener('wheel', mouseWheelHandler);
		}

		return () => {
			if (windowWidth >= TABLET_MEDIA_POINT) {
				window.removeEventListener('wheel', mouseWheelHandler);
			}
		};
	}, []);

	return (
		currentPageData ? (
			<section className="section no_bottom_offset_mod">
				{currentPageData.map && currentPageData.map.close_map_button ? (
					<div className="close_map_btn" onClick={toggleMap} onKeyDown={toggleMap} ref={$closeMapBtn} role="button" tabIndex={0}>
						<span className="close_map_btn_in">{currentPageData.map.close_map_button[langApp]}</span>
					</div>
				) : null}
				<div className="section_in full_mod contact_mod" ref={$contactWrapper}>
					{currentPageData.page_title ? (
						<h2
							className="section_title h1_mod accent_mod style_mod weight_mod pos_mod offset_3_mod"
							ref={$sectionTitle}
						>
							{currentPageData.page_title[langApp]}
						</h2>
					) : null}
					<div className="contact_cols">
						<div className="contact_col">
							<div className="contact_list">
								{currentPageData.address ? currentPageData.address.map((address_row, index) => {
									return (
										<div
											className="contact_item"
											key={`contactItem${index}`}
										>
											{ReactHtmlParser(address_row[langApp])}
										</div>
									);
								}) : null}
							</div>
						</div>
						<div className="contact_col">
							<div className="contact_col_decor" />
							<div className="contact_links">
								{currentPageData.contacts_list ? currentPageData.contacts_list.map((contacts_item, index) => {
									return (
										<div className="contact_link_item" key={index}>
											<div className="contact_link_title">{contacts_item.name[langApp]}</div>
											{contacts_item.type === 'email' ? (
												<a className="contact_link" href={`mailto:${contacts_item.link}`}>{contacts_item.title}</a>
											) : (
												<a className="contact_link" href={`tel:${contacts_item.link}`}>{contacts_item.title}</a>
											)}
										</div>
									);
								}) : null}
							</div>
						</div>
					</div>
					{currentPageData.messengers ? (
						<ul className="social_list contact_mod offset_mod">
							{currentPageData.messengers.map((messengers_item, index) => {
								const iconClass = index === 1 ? 'size_mod' : '';

								return (
									<li
										className="social_item"
										key={index}
										ref={ref => {
											socialListItems.current[index] = ref;
										}}
									>
										<a className="social_link contact_mod" href={`${messengers_item.link}`}>
											{/* <Icon className={`icon current_mod ${iconClass}`} /> */}
											<img
												className={`social_img ${iconClass}`}
												src={`${messengers_item.icon}`}
												alt={`${messengers_item.name}`}

											/>
										</a>
									</li>
								);
							})}
						</ul>
					) : null}
				</div>
				{currentPageData.map && currentPageData.map.open_map_button ? (
					<button className="open_map_btn" onClick={toggleMap} type="button">
						<span className="open_map_btn_in" ref={$openMapBtn}>{currentPageData.map.open_map_button[langApp]}</span>
					</button>
				) : null}
				<div className="contact_map" ref={$mapWrapper}>
					<div className="contact_map_in" ref={$mapInner}>
						<Map
							mapData={currentPageData.map}
							isMapOpen={isMapOpen}
							setMapOpen={setMapOpen}
						/>
					</div>
					{/* <MapOverlay pathRef={$pathRef} /> */}
				</div>
			</section>
		) : null
	);
};

export default ContactsPage;
