export const TABLET_MEDIA_POINT = 1024;

export const STATUS_PENDING = 'pending';
export const STATUS_FULFILLED = 'fulfilled';
export const STATUS_REJECTED = 'rejected';

export const MENU_CLOSED_STATE = 'closed';
export const MENU_OPEN_STATE = 'opened';
export const GLOBAL_PAGE_ANIM_DELAY = 1.2;

export const MENU_STATE = {
	DEFAULT: 'closed',
	OPEN: 'open',
	OPENING: 'opening',
	CLOSING: 'closing',
};

export const AVAILABLE_APP_LANG = ['de', 'fr', 'it', 'en'];

export const filesByLang = (lang) => `files_${lang}`;

export const CRISP_CHAT_WEBSITE_ID = '7d725d4a-5139-4ea8-bcd1-076bb222cbcc';

export const VIDEO_LOADING_STATUS = {
	pending: 'pending',
	loaded: 'loaded',
};
