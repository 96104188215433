/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect, useContext } from 'react';
import { gsap } from 'gsap';
import { AppDataContext } from 'context/appData';
import { SanitaizedText } from 'components/SanitaizedText';
import { getPageById } from 'utils';
import { filesByLang, GLOBAL_PAGE_ANIM_DELAY } from 'utils/constant';
import { DownloadLink } from 'components/DownloadLink';
import { UiContext } from 'context/ui';

const LogikalPage = () => {
	const { langApp, pagesData } = useContext(AppDataContext);
	const [currentPageData, setCurrentPageData] = useState(null);
	const {
		actionBannerAnimTl,
		faqBlockAnimTl,
		isPreloaderActive,
	} = useContext(UiContext);

	const [pageAnimation] = useState(gsap.timeline({
		paused: true,
	}));

	const pageAnim = () => {
		if (actionBannerAnimTl.progress() === 0 && faqBlockAnimTl.progress() === 0) {
			pageAnimation
				.addLabel('start')
				.add(actionBannerAnimTl.tweenFromTo('onload_anim_start', 'onload_anim_end'), 'start')
				.add(faqBlockAnimTl.tweenFromTo('onload_anim_start', 'onload_anim_end'), 'start+=.2');
		}
	};

	useEffect(() => {
		const currentData = getPageById(pagesData, 1617);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.data) {
			setCurrentPageData(preparedCurrentPageData.data);
		}
	}, [pagesData]);

	useEffect(() => {
		if (currentPageData) {
			pageAnim();
		}
	}, [currentPageData]);

	useEffect(() => {
		if (!isPreloaderActive) {
			gsap.delayedCall(GLOBAL_PAGE_ANIM_DELAY, () => {
				pageAnimation.play();
			});
		}
	}, [isPreloaderActive]);

	if (!currentPageData) return null;

	const langData = currentPageData[filesByLang(langApp)];
	const langFile = langData.file;
	// if (!langFile) {
	// 	console.error('Missing translation data');
	// }
	const instructions = langFile.filter(file => file.category === 'category1');
	const imports = langFile.filter(file => file.category === 'category2');

	return (
		<section className="section logikal">
			<h1 className="logikal__title">{currentPageData.page_title[langApp]}</h1>
			<div className="logikal__content">
				<h2 className="logikal__subtitle">
					{currentPageData.page_subtitle[langApp]}
				</h2>
				<div className="logikal__text">
					<SanitaizedText>
						{currentPageData.page_description[langApp]}
					</SanitaizedText>
				</div>
				<h3 className="logikal__warn">
					<SanitaizedText>
						{langData.files_section_title}
					</SanitaizedText>
				</h3>
				<div className="logikal__links">
					<ul className="logikal__links_list">
						{instructions.map(
							({
								file_icon, title, name, uploaded_file, type, size,
							}) => (
								<li className="logikal__links_item" key={name}>
									<DownloadLink file_icon={file_icon} title={title} name={name} uploaded_file={uploaded_file} type={type} size={size} />
								</li>
							),
						)}
					</ul>
					<ul className="logikal__links_list">
						{imports.map(
							({
								file_icon, title, name, uploaded_file, type, size,
							}) => (
								<li className="logikal__links_item" key={name}>
									<DownloadLink file_icon={file_icon} title={title} name={name} uploaded_file={uploaded_file} type={type} size={size} />
								</li>
							),
						)}
					</ul>
				</div>
				<div className="logikal__note">
					{langData.files_info_text}
				</div>
				<div className="logikal__info">
					<SanitaizedText>
						{langData.files_bottom_text}
					</SanitaizedText>
				</div>
			</div>
		</section>
	);
};

export default LogikalPage;
