import React, { useContext } from 'react';
import { AppDataContext } from 'context/appData';
import ReactHtmlParser from 'react-html-parser';
import { ReactComponent as SmallArrowIcon } from 'i/icons/small_arrow_r.svg';

const ServicesItem = ({
	url,
	image,
	title,
	description,
}) => {
	const { langApp } = useContext(AppDataContext);

	const preparedHrefLink = url && url.url ? url.url : '#';
	const preparedTargetLink = url && url.target ? url.target : '';

	return (
		<a className="section_slide_item_in" href={preparedHrefLink} target={preparedTargetLink}>
			{image && image.url && (
				<div className="section_slide_img_w">
					<img className="section_slide_img" src={image.url} alt={image.alt} />
				</div>
			)}
			<div className="section_slide_decor">
				<div className="section_slide_decor_in" />
			</div>
			<div className="section_slide_content">
				<div className="section_slide_content_in">
					{title && (
						<h3 className="section_slide_title">{title[langApp]}</h3>
					)}
					{description && (
						<div className="section_slide_descr">{ReactHtmlParser(description[langApp])}</div>
					)}
				</div>
			</div>
			<span className="section_slide_icon">
				<SmallArrowIcon className="icon icon-small_arrow_r size_mod" />
			</span>
		</a>
	);
};

export default ServicesItem;
