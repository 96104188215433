import React, { useContext } from 'react';
import { AppDataContext } from 'context/appData';
import ReactHtmlParser from 'react-html-parser';

const InvitationAdditionalSection = ({
	sectionTitle,
	listArray,
}) => {
	const { langApp } = useContext(AppDataContext);

	return (
		<section className="section bg_2_mod height_2_mod">
			<div className="section_in v2_mod">
				{sectionTitle && (
					<h2 className="section_title h4_mod align_mod color_2_mod style_mod offset_2_mod pos_3_mod">{ReactHtmlParser(sectionTitle[langApp])}</h2>
				)}
				<div className="info_list">
					{listArray && listArray.length ? (
						listArray.map(({
							info_detail_item: detailItem,
						}, index) => {
							return (
								<div
									className="info_list_item"
									key={index}
								>
									{detailItem && (
										ReactHtmlParser(detailItem[langApp])
									)}
								</div>
							);
						})
					) : null}
				</div>
			</div>
		</section>
	);
};

export default InvitationAdditionalSection;
