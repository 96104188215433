import jsonAppData from './jsonAppData';

export const getPageById = (allPages, pageId) => {
	const resultPage = allPages.filter(({ id }) => {
		if (id === pageId) return true;

		return false;
	});

	return resultPage;
};

export const searchFieldValidation = fieldValue => {
	if (fieldValue.trim() === '') {
		return false;
	}

	return true;
};

export const filterByCategoryId = (categoryArray, id) => {
	if (!categoryArray && !categoryArray.length) return false;

	const res = categoryArray.filter(({
		categories,
	}) => {
		const isIdMatch = categories.includes(id);

		return isIdMatch;
	});

	return res;
};

export const setItemToLocalStorage = (keyName, keyValue) => {
	window.localStorage.setItem(keyName, keyValue);
};

export const getItemFromLocalStorage = keyName => {
	return window.localStorage.getItem(keyName);
};

export const nameValidation = ({
	inputValue,
	requiredErrorMessage,
	validErrorMessage,
}) => {
	const preparedFieldValue = inputValue.toString();

	if (preparedFieldValue.trim() === '') {
		return requiredErrorMessage || jsonAppData.alertMessages.fieldRequired.en;
	}
	if (/[^a-zA-Z0-9 -]/.test(preparedFieldValue)) {
		return validErrorMessage || jsonAppData.alertMessages.fieldInvalidCharacters.en;
	}

	return null;
};

export const emailValidation = ({
	inputValue,
	requiredErrorMessage,
	validErrorMessage,
}) => {
	if (
		/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
			inputValue,
		)
	) {
		return null;
	}
	if (inputValue.trim() === '') {
		return requiredErrorMessage || jsonAppData.alertMessages.emailFieldRequired.en;
	}
	return validErrorMessage || jsonAppData.alertMessages.emailFieldValid.en;
};

export const selectValidation = ({
	selectValue,
	requiredErrorMessage,
}) => {
	const preparedFieldValue = selectValue ? selectValue.toString() : '';

	if (preparedFieldValue.trim() === '') {
		return requiredErrorMessage || jsonAppData.alertMessages.fieldRequired.en;
	}

	return null;
};

export const isObjectEmpty = obj => {
	return Object.keys(obj).length === 0;
};

export const debounce = (func, wait) => {
	let timeout = null;
	return (...args) => {
		const later = () => {
			timeout = null; // Clear timeout to allow for a new immediate call after wait
		};

		if (timeout) {
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
		} else {
			func(...args);
		}
	};
};

export const showCrispChat = () => {
	if (window.$crisp) {
		window.$crisp.push(['do', 'chat:show']);
	}
};

export const hideCrispChat = () => {
	if (window.$crisp) {
		window.$crisp.push(['do', 'chat:hide']);
	}
};

export const getLocalStorageItem = itemName => {
	if (itemName) {
		return localStorage.getItem(itemName);
	}

	return null;
};

export const setLocalStorageItem = ({
	keyName,
	keyValue,
}) => {
	if (keyName && keyValue) {
		return localStorage.setItem(keyName, keyValue);
	}

	return null;
};

export const getValueType = value => {
	const type = typeof value;

	if (type !== 'object') return type;

	if (value instanceof Array) return 'array';

	if (value instanceof Date) return 'date';

	return 'object';
};

export const detectSafariBrowser = () => {
	return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const cloneObjectWithoutReference = object => {
	return JSON.parse(JSON.stringify(object));
};

export const detectIosSystem = () => {
	return [
		'iPad Simulator',
		'iPhone Simulator',
		'iPod Simulator',
		'iPad',
		'iPhone',
		'iPod',
	].includes(navigator.platform)
		// iPad on iOS 13 detection
		|| (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
};

export const isRelativeUrl = (url) => {
	try {
		const parsedUrl = new URL(url);

		return !parsedUrl.host;
	} catch (error) {
		return true;
	}
};
