import React, { useContext } from 'react';
import { AppDataContext } from 'context/appData';
import { isRelativeUrl } from 'utils';
import { Link } from 'react-router-dom';

const ServicesFilter = ({ filterList }) => {
	const { langApp } = useContext(AppDataContext);

	return (
		<ul className="section_services_list">
			{filterList.map(({
				service_link,
			}, index) => {
				const preparedFiles = service_link.files && (service_link.files[langApp] || '#');
				const preparedUrl = service_link.url || preparedFiles;
				const preparedTitle = service_link.text[langApp] || '';

				return (
					<li className="section_services_li" key={index}>
						{isRelativeUrl(preparedUrl) ? (
							<Link className="section_services_link" to={preparedUrl} rel="noreferrer">
								{preparedTitle}
							</Link>
						) : (
							<a className="section_services_link" href={preparedUrl} rel="noreferrer">
								{preparedTitle}
							</a>
						)}

					</li>
				);
			})}
		</ul>
	);
};

export default ServicesFilter;
