import React,
{
	useRef,
	useContext,
	useEffect,
	useState,
} from 'react';
import { gsap } from 'gsap';
import { SplitText } from 'libs/SplitText';

import { AppDataContext } from 'context/appData';
import { useLocation, useNavigate } from 'react-router';
import { UiContext } from 'context/ui';
import { getPageById, hideCrispChat, showCrispChat } from 'utils';

gsap.registerPlugin(SplitText);

const BannerSignUp = () => {
	const navigate = useNavigate();
	const { langApp, pagesData, themeSettings } = useContext(AppDataContext);
	const {
		signUpBannerAnimTl,
		faqBlockAnimTl,
		windowWidth,
		windowHeight,
	} = useContext(UiContext);
	const [bannerData, setBannerData] = useState(null);
	const location = useLocation();

	const $bannerEl = useRef();
	const $titleWrapEl = useRef();
	const $title = useRef();
	const $btnWrap = useRef();
	const $btn = useRef();
	const $bgWrap = useRef();
	const $bg = useRef();
	const $logoWrap = useRef();
	const $logo = useRef();

	const createBannerAnimation = () => {
		const $titleLetters = new SplitText($title.current, { type: 'chars' });

		const widthBgEl = $bgWrap.current.offsetWidth;
		const heightBgEl = $bgWrap.current.offsetHeight;

		const secondStepScalePercent = {
			width: 1.3,
			height: 0.23,
		};

		const finalStepScalePercent = {
			width: 1.9,
			height: 1.1,
		};

		const scaleXStepOne = (windowWidth * secondStepScalePercent.width) / widthBgEl;
		const scaleYStepOne = (windowHeight * secondStepScalePercent.height) / heightBgEl;

		const scaleXFinalStep = (windowWidth * finalStepScalePercent.width) / widthBgEl;
		const scaleYFinalStep = (windowHeight * finalStepScalePercent.height) / heightBgEl;

		signUpBannerAnimTl
			.addLabel('onload_anim_start')
			.to($bannerEl.current, {
				opacity: 1,
				pointerEvents: 'auto',
			}, 'onload_anim_start')
			.fromTo([$bgWrap.current, $bg.current], {
				xPercent: gsap.utils.wrap([-100, 100]),
			}, {
				xPercent: 0,
				duration: 1,
				ease: 'none',
			}, 'onload_anim_start')
			.fromTo($titleLetters.chars, {
				y: 200,
				opacity: 0,
			}, {
				y: 0,
				opacity: 1,
				stagger: 0.01,
				ease: 'power2.out',
			}, 'onload_anim_start+=.4')
			.fromTo([$logoWrap.current, $logo.current], {
				xPercent: gsap.utils.wrap([100, -100]),
				yPercent: gsap.utils.wrap([100, -100]),
			}, {
				xPercent: 0,
				yPercent: 0,
				duration: 0.6,
				ease: 'none',
			}, 'onload_anim_start+=.6')
			.fromTo([$btnWrap.current, $btn.current], {
				xPercent: gsap.utils.wrap([-100, 100]),
			}, {
				xPercent: 0,
				duration: 0.6,
				ease: 'none',
			}, 'onload_anim_start+=1')
			.addLabel('onload_anim_end')
			.addLabel('page_transition_start')
			.to($bgWrap.current, {
				transformOrigin: '50% 96%',
				scaleX: scaleXStepOne,
				scaleY: scaleYStepOne,
				duration: 0.8,
				ease: `expoScale(1, ${scaleYStepOne})`,
			}, 'page_transition_start')
			.to([$titleWrapEl.current, $btnWrap.current, $logoWrap.current], {
				opacity: 0,
			}, 'page_transition_start')
			.to($bgWrap.current, {
				scaleX: scaleXFinalStep,
				scaleY: scaleYFinalStep,
				duration: 0.8,
				ease: `expoScale(1, ${scaleYFinalStep})`,
				onStart: () => {
					gsap.delayedCall(0.4, () => {
						navigate('/invitation');
						faqBlockAnimTl.seek('onload_anim_start');
					});
				},
			}, 'page_transition_start+=.4')
			.to($bgWrap.current, {
				y: '-104vh',
				duration: 0.8,
				ease: 'power2.out',
			})
			.addLabel('page_transition_end');
	};

	const handleOnClick = () => {
		hideCrispChat();
		signUpBannerAnimTl.tweenFromTo('page_transition_start', 'page_transition_end', {
			onComplete: () => {
				signUpBannerAnimTl.seek('onload_anim_start');
				showCrispChat();
			},
		});
	};

	useEffect(() => {
		if (pagesData && themeSettings) {
			const invitationPageData = getPageById(pagesData, 283); // empty array if page is disabled
			if (invitationPageData && invitationPageData.length > 0) {
				// only show banner if the Invitation page is enabled
				setBannerData(themeSettings.footer_banners.signUp);
			}
		}
	}, [themeSettings, pagesData]);

	useEffect(() => {
		if (bannerData) {
			createBannerAnimation();
		}
	}, [bannerData]);

	useEffect(() => {
		if (location.pathname === '/') {
			gsap.fromTo($bannerEl.current, {
				opacity: 1,
			}, {
				opacity: 0,
				onComplete: () => {
					signUpBannerAnimTl.seek('onload_anim_start');
				},
			});
		}
	}, [location]);

	return (
		bannerData ? (
			<div className="page_bottom_block v2_mod" ref={$bannerEl}>
				<div className="page_bottom_block_in">
					<div className="page_bottom_block_bg" ref={$bgWrap}>
						<div className="page_bottom_block_bg_in" ref={$bg} />
					</div>
					<div className="page_bottom_block_decor_w v2_mod" ref={$logoWrap}>
						<div className="page_bottom_block_decor" ref={$logo}>
							{bannerData.image ? (
								<img className="page_bottom_block_decor_img" src={bannerData.image} alt="" />
							) : null}
						</div>
					</div>
					<div className="page_bottom_block_title_w" ref={$titleWrapEl}>
						{bannerData.text && (
							<div className="page_bottom_block_title v2_mod" ref={$title} dangerouslySetInnerHTML={{ __html: bannerData.text[langApp] }} />
						)}
					</div>
					<div className="page_bottom_block_btn_w" ref={$btnWrap}>
						<div className="page_bottom_block_btn" ref={$btn}>
							{bannerData.button && bannerData.button.title && (
								<button
									className="btn_base"
									type="button"
									onClick={handleOnClick}
								>
									<span className="btn_title">{bannerData.button.title[langApp]}</span>
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		) : null
	);
};

export default BannerSignUp;
