/* eslint-disable indent, quotes */
export const TRANSLATION_DE = {
  i18n: {
    language: "Sprache",
    languageName: "Deutsch",
  },
  rackReturn: {
    pickupLocation: "Abholort",
    pickupStreet: "Strasse",
    pickupCity: "Ort",
    pickupCityPlaceholder: "Abholort",
    doTrackLocation: "✓ Meine Position wird verfolgt",
    dontTrackLocation: "✖ Meine Position wird nicht verfolgt",
    companyName: "Firma",
    enterYourCompany: "Gib deine Firma ein",
    contactName: "Kontakt",
    enterYourName: "Gib deinen Namen ein",
    returnForRackFmt: "Retourformular für Gestell Nr. {}",
    rackId: "Gestell Nr.",
    comments: "Kommentare",
    enterAnyComments: "Weitere Anmerkungen",
    submit: "Absenden",
    companyNameEmptyError: "Firma wird benötigt",
    contactNameEmptyError: "Kontaktname wird benötigt",
    addressEmptyError: "Adresse oder Kartenpunkt wird benötigt",
  },
  rackReturnConfirmation: {
    thankYou: "Vielen Dank. Wir sind bald dort ...",
  },
  rackReturnEmail: {
    subjectFmt: "Gestell Retour Nr. {}",
    rackIdReadyAtCompanyInCityFmt: "Gestell Nr. {rackId} ist Abholbereit bei {companyName} in {city}",
    rackIdReadyInCityFmt: "Gestell Nr. {rackId} ist Abholbereit in {city}",
    bodyHtmlFmt: "<p>{heading}</p><p><ul><li>Firma: <strong>{companyName}</strong></li><li>Kontakt: <strong>{contactName}</strong></li><li>Adresse: <strong>{address}</strong></li><li>Sprache: <strong>{language}</strong></li></ul></p><p>Anmerkungen:<br />{comments}</p>",
  },
};
