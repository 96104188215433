import { AppDataContext } from 'context/appData';
import React, { useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';

const HomeSliderContentItem = ({
	infoItems,
}) => {
	const { langApp } = useContext(AppDataContext);

	return (
		<div className="home_slider_content_w full_mod offset_3_mod">
			<div className="home_slider_info_w">
				{Object.keys(infoItems).map((gridInfoKey, index) => {
					const item = infoItems[gridInfoKey];
					const infoValueCols = item.item_subcols;

					return (
						<div className="home_slider_info_item" key={`${index}_info`}>
							<div className="home_slider_info_item_in">
								{index !== 0 ? (
									<div className="home_slider_info_decor_line homeSliderInfoDecorLine" />
								) : null}
								<div className="info_value_w">
									<div className="info_value_line">
										{item ? (
											<div className={`info_value_title ${item.item_subcols ? 'size_mod' : ''}`}>{ReactHtmlParser(item[langApp])}</div>
										) : null}
										{infoValueCols ? (
											<div className="info_value_col_w">
												{Object.keys(infoValueCols).map((infoColKey, subColIndex) => {
													const {
														title,
														img,
													} = infoValueCols[infoColKey];

													return (
														<div className="info_value_col" key={subColIndex}>
															<div className="info_value_col_title size_mod">{ReactHtmlParser(title[langApp])}</div>
															<div className="info_value_col_img_w">
																<img className="info_value_col_img_w" src={img} alt={title[langApp]} />
															</div>
														</div>
													);
												})}
											</div>
										) : null}
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default HomeSliderContentItem;
