/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable indent */
/* eslint-disable react/no-danger */
/* eslint-disable quotes */
import DOMPurify from "isomorphic-dompurify";

export function SanitaizedText({ children }) {
  if (!children) return null;

  const sanitized = DOMPurify.sanitize(children);

  return <span dangerouslySetInnerHTML={{ __html: sanitized }} />;
}

export default SanitaizedText;
