import React, {
	useContext,
	useState,
	useEffect,
	useRef,
} from 'react';
import { AppDataContext } from 'context/appData';
import { getPageById } from 'utils';
import ReactHtmlParser from 'react-html-parser';
import { gsap } from 'gsap';
import { SplitText } from 'libs/SplitText';
import { GLOBAL_PAGE_ANIM_DELAY, TABLET_MEDIA_POINT } from 'utils/constant';

import { OfferList } from 'components/Offer';
import { UiContext } from 'context/ui';

gsap.registerPlugin(SplitText);

const WhatWeOfferPage = () => {
	const { pagesData, langApp } = useContext(AppDataContext);
	const {
		actionBannerAnimTl,
		faqBlockAnimTl,
		windowWidth,
		isPreloaderActive,
	} = useContext(UiContext);

	const [currentPageData, setCurrentPageData] = useState(null);
	const $sectionTitle = useRef();
	const $sectionDescr = useRef();

	const [pageAnimation] = useState(gsap.timeline({
		paused: true,
	}));

	const getPageData = () => {
		const currentData = getPageById(pagesData, 40);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.data) {
			setCurrentPageData(preparedCurrentPageData.data);
		}
	};

	const pageAnim = () => {
		const sectionTitleLetters = new SplitText($sectionTitle.current, { type: 'chars' });
		let sectionDescrLines;

		if ($sectionDescr.current) {
			let $sectionDescrParagraph = $sectionDescr.current.querySelector('p');

			if ($sectionDescrParagraph) {
				sectionDescrLines = new SplitText($sectionDescrParagraph, { type: 'lines' });
			}
		}

		pageAnimation
			.addLabel('start')
			.fromTo(sectionTitleLetters.chars, {
				yPercent: -200,
				opacity: 0,
			}, {
				yPercent: 0,
				opacity: 1,
				stagger: 0.1,
				duration: 0.6,
				ease: 'power3.out',
			}, 'start')
			.fromTo(sectionDescrLines.lines, {
				x: -120,
				opacity: 0,
			}, {
				x: 0,
				opacity: 1,
				stagger: -0.1,
				duration: 0.8,
				ease: 'power3.out',
			}, 'start+=.6')
			.add(faqBlockAnimTl.tweenFromTo('onload_anim_start', 'onload_anim_end'), 'start+=.8')
			.add(actionBannerAnimTl.tweenFromTo('onload_anim_start', 'onload_anim_end'), 'start+=2')
			.fromTo('.offer_item_in', {
				yPercent: 100,
				xPercent: 20,
				opacity: 0,
				rotateY: -25,
			}, {
				yPercent: 0,
				xPercent: 0,
				opacity: 1,
				rotateY: 0,
				duration: 1,
				stagger: 0.3,
				ease: 'power2.out',
			}, 'start+=1')
			.set('.offer_item', {
				perspective: 'none',
				pointerEvents: 'auto',
			});

		if (windowWidth < TABLET_MEDIA_POINT) {
			gsap.delayedCall(3, () => {
				pageAnimation
					.fromTo('.slick-dots', {
						opacity: 0,
					}, {
						opacity: 1,
					}, '-=1');
			});
		}
	};

	useEffect(() => {
		if (pagesData) {
			getPageData();
		}
	}, [pagesData]);

	useEffect(() => {
		if (currentPageData) {
			pageAnim();
		}
	}, [currentPageData]);

	useEffect(() => {
		if (!isPreloaderActive) {
			gsap.delayedCall(GLOBAL_PAGE_ANIM_DELAY, () => {
				pageAnimation.play();
			});
		}
	}, [isPreloaderActive]);

	return (
		currentPageData ? (
			<section className="section">
				<div className="section_in">
					{currentPageData.page_title && (
						<h2 className="section_title h1_mod accent_mod weight_mod" ref={$sectionTitle}>{ReactHtmlParser(currentPageData.page_title[langApp])}</h2>
					)}
					{currentPageData.page_description && (
						<div className="section_descr offset_3_mod limit_mod" ref={$sectionDescr}>{ReactHtmlParser(currentPageData.page_description[langApp])}</div>
					)}
					{currentPageData.offers_list ? (
						<div className="offer_list_w">
							<OfferList
								offersList={currentPageData.offers_list}
							/>
						</div>
					) : null}
				</div>
			</section>
		) : null
	);
};

export default WhatWeOfferPage;
