import React, { useContext } from 'react';
import { AppDataContext } from 'context/appData';

const InvitationScheduleList = ({
	scheduleList,
}) => {
	const { langApp } = useContext(AppDataContext);

	return (
		<div className="invitation_schedule_list">
			{scheduleList && scheduleList.length ? (
				scheduleList.map(({
					event,
				}, index) => {
					return (
						<div className="invitation_schedule_item" key={`${index}_schedule`}>
							<div className="invitation_schedule_text" dangerouslySetInnerHTML={{ __html: event[langApp] }} />
						</div>
					);
				})
			) : null}
		</div>
	);
};

export default InvitationScheduleList;
