import React, { useContext } from 'react';
import classNames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import { AppDataContext } from 'context/appData';

const FaqItem = ({
	title,
	text,
	fullMod,
}) => {
	const { langApp } = useContext(AppDataContext);
	const faqItemClasses = classNames('faq_item', {
		full_mod: fullMod,
	});

	return (
		title && title[langApp] ? (
			<div className={faqItemClasses}>
				<div className="faq_title">{ReactHtmlParser(title[langApp])}</div>
				{text && text[langApp] && (
					<div className="faq_text">{ReactHtmlParser(text[langApp])}</div>
				)}
			</div>
		) : null
	);
};

export default FaqItem;
