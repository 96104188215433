import { api } from 'utils/api';

const getAllPages = async () => {
	try {
		const res = await api.get('/wp/v2/pages?per_page=50');

		return res.data;
	} catch (err) {
		console.error(err);

		return err;
	}
};

const getMainMenuItems = async () => {
	try {
		const res = await api.get('/nav/main-menu');

		return res.data;
	} catch (err) {
		console.error(err);

		return err;
	}
};

const getAllFaqData = async () => {
	try {
		const res = await api.get('/wp/v2/faqs/archive');

		return res.data;
	} catch (err) {
		console.error(err);

		return err;
	}
};

const getAllFaqCategories = async () => {
	try {
		const res = await api.get('/wp/v2/faqs/categories');

		return res.data;
	} catch (err) {
		console.error(err);

		return err;
	}
};

const getThemeSettings = async () => {
	try {
		const res = await api.get('/wp/v2/theme-settings');

		return res.data;
	} catch (err) {
		console.error(err);

		return err;
	}
};

export {
	getAllPages,
	getThemeSettings,
	getMainMenuItems,
	getAllFaqData,
	getAllFaqCategories,
};
