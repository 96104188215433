import React,
{
	useRef,
	useContext,
	useState,
	useEffect,
	useMemo,
} from 'react';
import { gsap } from 'gsap';
import { SplitText } from 'libs/SplitText';

import { GLOBAL_PAGE_ANIM_DELAY, TABLET_MEDIA_POINT } from 'utils/constant';
import { TeamSlider } from 'components/Team';
import { AppDataContext } from 'context/appData';
import { getPageById } from 'utils';
import ReactHtmlParser from 'react-html-parser';
import { UiContext } from 'context/ui';

gsap.registerPlugin(SplitText);

const TeamPage = () => {
	const { pagesData, langApp } = useContext(AppDataContext);
	const { signUpBannerAnimTl, windowWidth, isPreloaderActive } = useContext(UiContext);
	const [currentPageData, setCurrentPageData] = useState(null);
	const sectionTitle = useRef();
	const sectionDescr = useRef();

	const [pageAnimation] = useState(gsap.timeline({
		paused: true,
	}));

	const getPageData = () => {
		const currentData = getPageById(pagesData, 193);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.data) {
			setCurrentPageData(preparedCurrentPageData.data);
		}
	};

	const employees = useMemo(() => {
		// Filter out "Privately published" employees (e.g. drafts)
		return currentPageData?.employees?.filter(o => !o.name.startsWith('Private: '));
	}, [currentPageData]);

	const pageAnim = () => {
		pageAnimation
			.addLabel('anim')
			.fromTo('.slider_control.next_mod', {
				scale: 0,
				transformOrigin: '0% 0%',
			}, {
				scale: 1,
				duration: 0.8,
			})
			.addLabel('sliderStep')
			.fromTo('.team_item', {
				opacity: 0,
				scale: 0,
			}, {
				opacity: 1,
				scale: 1,
				duration: 0.4,
				stagger: 0.2,
			}, 'sliderStep')
			.fromTo('.team_quote', {
				opacity: 0,
				x: -200,
			}, {
				opacity: 1,
				x: 0,
				duration: 0.4,
				stagger: 0.2,
			}, 'sliderStep')
			.add(signUpBannerAnimTl.tweenFromTo('onload_anim_start', 'onload_anim_end'), 'sliderStep');

		if (sectionTitle.current && sectionDescr.current) {
			const $sectionTitleLetters = new SplitText(sectionTitle.current, { type: 'chars' });

			pageAnimation
				.fromTo($sectionTitleLetters.chars, {
					yPercent: -200,
					opacity: 0,
				}, {
					yPercent: 0,
					opacity: 1,
					stagger: 0.1,
					duration: 0.6,
				}, 'anim+=.2');

			const $sectionDescrParagraph = sectionDescr.current.querySelector('p');

			if ($sectionDescrParagraph) {
				const $sectionDescrLines = new SplitText($sectionDescrParagraph, { type: 'lines' });

				pageAnimation
					.fromTo($sectionDescrLines.lines, {
						xPercent: -50,
						opacity: 0,
					}, {
						xPercent: 0,
						opacity: 1,
						stagger: -0.2,
					}, 'anim+=.2');
			}
		}

		if (windowWidth < TABLET_MEDIA_POINT) {
			gsap.delayedCall(2, () => {
				pageAnimation
					.fromTo('.slick-dots', {
						opacity: 0,
					}, {
						opacity: 1,
					});
			});
		}
	};

	useEffect(() => {
		if (pagesData) {
			getPageData();
		}
	}, [pagesData]);

	useEffect(() => {
		if (currentPageData) {
			gsap.delayedCall(0.1, () => {
				pageAnim();
			});
		}
	}, [currentPageData]);

	useEffect(() => {
		if (!isPreloaderActive) {
			gsap.delayedCall(GLOBAL_PAGE_ANIM_DELAY, () => {
				pageAnimation.play();
			});
		}
	}, [isPreloaderActive]);

	return (
		currentPageData ? (
			<section className="section">
				<div className="section_in">
					{currentPageData.page_title ? (
						<h1 className="section_title h1_mod accent_mod style_mod weight_mod" ref={sectionTitle}>{currentPageData.page_title[langApp]}</h1>
					) : null}
					{currentPageData.page_description ? (
						<div
							className="section_descr offset_2_mod"
							ref={sectionDescr}
						>
							{ReactHtmlParser(currentPageData.page_description[langApp])}
						</div>
					) : null}
					{employees ? (
						<TeamSlider employees={employees} />
					) : null}
				</div>
			</section>
		) : null
	);
};

export default TeamPage;
