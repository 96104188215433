import React, { useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { AppDataContext } from 'context/appData';

const MapMarkerPopup = ({
	markerPopupData,
}) => {
	const { langApp } = useContext(AppDataContext);

	return (
		<div className="map_marker_popup">
			{markerPopupData.name && (
				<div className="map_marker_popup_info title_mod">{markerPopupData.name}</div>
			)}
			{markerPopupData.address && (
				<div className="map_marker_popup_info text_mod">{ReactHtmlParser(markerPopupData.address)}</div>
			)}
			{markerPopupData.link && (
				<a
					rel="noreferrer"
					href={markerPopupData.link.url}
					target="_blank"
					className="map_marker_popup_link"
				>
					{markerPopupData.link.title[langApp]}
				</a>
			)}
		</div>
	);
};

export default MapMarkerPopup;
